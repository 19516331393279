import React from 'react'
import Helmet from 'react-helmet'

export default ({
  metaTitle = 'Patrick McCarthy',
  metaDescription,
  metaKeywords,
  openGraphTitle = 'Patrick McCarthy',
  openGraphDescription,
  openGraphImage,
  twitterTitle = 'Patrick McCarthy',
  twitterDescription,
  twitterImage,
  pathname = '/',
  siteName = 'Patrick McCarthy',
  siteUrl = 'https://www.patrickmccarthy.lol',
}) => (
  <Helmet>
    {metaTitle && <title>{metaTitle}</title>}
    {metaDescription && <meta name="description" content={metaDescription} />}
    <meta name="keywords" content={metaKeywords} />

    <meta property="og:url" content={`${siteUrl}${pathname}`} />
    <meta property="og:title" content={openGraphTitle} />
    <meta property="og:site_name" content={siteName} />
    <meta property="og:description" content={openGraphDescription} />
    {openGraphImage && (
      <meta property="og:image" content={openGraphImage.url} />
    )}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={twitterTitle} />
    <meta name="twitter:description" content={twitterDescription} />
    <meta name="twitter:url" content={`${siteUrl}${pathname}`} />
    {twitterImage && (
      <meta name="twitter:image:src" content={twitterImage.url} />
    )}
    <html lang="en" />
  </Helmet>
)
