import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import richText from '../serializers/richText'
import ProjectList from '../components/ProjectList'
import Seo from '../components/Seo'
import Reveal from '../components/Reveal'

export default ({location, pageContext}) => {
  const {seo = {}, description, projects = []} = pageContext

  const metaTitle = seo.metaTitle || 'Patrick McCarthy'
  const openGraphTitle = seo.openGraphTitle || 'Patrick McCarthy'
  const twitterTitle = seo.twitterTitle || 'Patrick McCarthy'

  return (
    <React.Fragment>
      <Seo
        metaTitle={metaTitle}
        metaDescription={seo.metaDescription}
        metaKeywords={seo.metaKeywords}
        openGraphTitle={openGraphTitle}
        openGraphDescription={seo.openGraphDescription}
        openGraphImage={seo.openGraphImage}
        twitterTitle={twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage}
        pathname={location.pathname}
      />
      <div id="work">
        <ProjectList projects={projects} />
      </div>

      <div className="mt5 grid-container mb5">
        <div className="row">
          <Reveal className="col c10--md c12" threshold={100}>
            <div className="sans--lg--lg sans--md home-description-rich-text reveal__slide">
              <BlockContent blocks={description} serializers={richText} />
            </div>
          </Reveal>
        </div>
      </div>
    </React.Fragment>
  )
}
