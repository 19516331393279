import React from 'react'
import Link from './Link'
import Reveal from './Reveal'

export default ({projects}) => {
  return <Projects projects={projects} />
}

const Projects = ({projects = []}) => (
  <section>
    {projects.map((project, index) => {
      if (project.url && project.image) {
        return <LiveProject key={project._key} {...project} />
      } else {
        return <DeadProject key={project._key} {...project} />
      }
    })}
  </section>
)

const LiveProject = ({title, url, image, year, studio, technologies, _key}) => (
  <Reveal threshold={100}>
    <Link
      className="db pr project-list-item project-list-item--live link-project"
      title={title}
      url={url}
      openInNewWindow
    >
      <div className="df fdc fdr--lg">
        <div className="project--section fs0 df">
          <div className="p4--lg p2 project--info">
            <h2 className="sans--sm--md sans--xs project-hover dib reveal__slide">
              {title}
            </h2>
            <p className="sans--sm--md sans--xs mb8 reveal__slide">{year}</p>
            <p className="sans--sm--md sans--xs reveal__slide">
              {'Made with '}
              <Link
                {...studio}
                openInNewWindow
                className="link-studio hover-opacity"
              >
                <span>{studio.title}</span>
              </Link>
            </p>
            <p className="sans--sm--md sans--xs reveal__slide">
              Using {technologies.join(', ')}
            </p>
          </div>
        </div>
        <div className="project--section fs0 pr">
          <div className="project--image-overlay pa fill bg--black df jce ais z1 pt5 pr5">
            <div className="color--white">↗</div>
          </div>

          <div className="image size--16x9">
            <picture>
              <source
                srcSet={`${image.url}?w=1200&auto=format&q=75`}
                media="(min-width: 1000px)"
              />
              <source
                srcSet={`${image.url}?w=800&auto=format&q=75`}
                media="(min-width: 400px)"
              />
              <img
                alt={image.alt}
                src={`${image.url}?w=680&auto=format&q=75`}
              />
            </picture>
          </div>
        </div>
      </div>
    </Link>
  </Reveal>
)

const DeadProject = ({title, year, studio, technologies, _key}) => {
  return (
    <div className="project--section dib project--section__dead color--gray">
      <div className="df project--section__dead-flex">
        <div className="p4--lg p2 project--info">
          <h2 className="sans--sm--md sans--xs project-hover dib">{title}</h2>
          <p className="sans--sm--md sans--xs mb8">{year}</p>
          <p className="sans--sm--md sans--xs">
            {'Made with '}
            <Link
              {...studio}
              openInNewWindow
              className="link-studio hover-opacity"
            >
              <span>{studio.title}</span>
            </Link>
          </p>
          <p className="sans--sm--md sans--xs">
            Using {technologies.join(', ')}
          </p>
        </div>
      </div>
    </div>
  )
}
